<template>
  <b-modal
    ref="password-modal"
    no-close-on-backdrop
    hide-footer
    title="เปลี่ยนรหัสผ่าน"
    ok-variant="success"
    ok-title="เปลี่ยนรหัสผ่าน"
    cancel-title="ยกเลิก"
    footer-class="justify-content-between"
    @show="resetModal"
    @hidden="resetModal"
    class="position-relative"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="ชื่อผู้ใช้"
      >
        <b-form-input
          v-model="input.username"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รหัสผ่านใหม่"
        label-for="input-new-password"
      >
        <b-form-input
          id="input-new-password"
          v-model="input.nPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ยืนยันรหัสผ่าน"
        label-for="input-confirm-password"
      >
        <b-form-input
          id="input-confirm-password"
          v-model="input.cPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึกรหัสผ่าน'}}</b-button>
      </div>
    </form>

    <loading
      :active="isProcess"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </b-modal>
</template>
<script>
import ManageAccountService from "@/services/ManageAccountService"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'ChangePasswordModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {
        _id: null,
        username: null,
        nPassword: null,
        cPassword: null
      }
    }
  },
  data() {
    return {
      isProcess: false,
      input: JSON.parse(JSON.stringify(this.data))
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.isProcess = false
      this.input = {
        _id: null,
        username: null,
        nPassword: null,
        cPassword: null
      }
    },
    showModal() {
      this.$refs['password-modal'].show()
    },
    hideModal() {
      this.$refs['password-modal'].hide()
    },
    handleSubmit() {

      if(this.input.nPassword !== this.input.cPassword) {
        Swal.fire({
          text: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((res)=>{
          this.input.cPassword = ''
        })
        return
      }

      const self = this
      this.isProcess = true
      ManageAccountService.changePassword(this.data._id, this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เปลี่ยนรหัสผ่านเสร็จเรียบร้อย',
          })
        }else{
          throw response
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e.message || 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
